import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import styled from "styled-components"
import { Link } from "gatsby"
import { keyframes } from "styled-components"

function Rooy() {
  return (
    <Layout>
      <SEO title="Past works" />
      <Wrapper>
        <CloseBtn>
          <Link to="/">
            <img src="images/icons/cancel.png" alt="cancel"></img>
          </Link>
        </CloseBtn>
        <ContentWrapper>
          <Bg1>
            <ContentHeaderWrapper> Rooy</ContentHeaderWrapper>
          </Bg1>
        </ContentWrapper>
        <Bg1>
          <ContentBodyWrapper>
            <Header>Overview</Header>
            <Body>
              Rooy opens shoe design submissions via challenge. If your shoe
              design get selected from the challenge, you will become a Rooy
              designer. Once you become a Rooy designer, Rooy takes care of
              prototyping, manufacturing, payments, and shipping. In addition,
              you (Rooy designer) get 10% of net sales.
            </Body>
            <Body>
              As lead designer at Rooy, I was responsible for guiding the UX and
              visual language guide line through development process includes
              assigning tasks to other designers. I collaborated with product
              management and engineering to define and implement innovative
              solutions for the product direction, visuals and experience.
            </Body>
          </ContentBodyWrapper>
        </Bg1>

        <Bg2>
          <ContentBodyWrapper>
            <Header>
              Design Challenge 1 :<span> Two group of targeted users</span>
            </Header>
            <img src="images/past/persona.png" alt="persona"></img>
            <Body>
              In this project, I led the UX design from the start to the end.
              Since T-Mobile planned the promotion through trade-in program, I
              worked with the marketing team. Also, work closely with assurance
              team to provide trade-in value to users quickly and smoothly
            </Body>

            <ContentSectionWrapper>
              <ContentGroupSectionWrapper>
                <BoldBody>Challenge</BoldBody>
                <Body>
                  As you can see from the above persona, Rooy's target users are
                  two groups, and it was a great deal of concern to whom to
                  focus on when designing their website. I focus on the way to
                  create web platform that engage all users
                </Body>
              </ContentGroupSectionWrapper>
              <ContentGroupSectionWrapper>
                <Body>
                  <BoldBody>Solution</BoldBody>
                </Body>
                <Body>
                  Both contents are valuable to both users. We will distinguish
                  the landing page on depends on users path and the different
                  contents on their status. (See image below)
                </Body>
              </ContentGroupSectionWrapper>
            </ContentSectionWrapper>
          </ContentBodyWrapper>
        </Bg2>

        <Bg3>
          <img src="images/past/ia.png" alt="IA"></img>
          <img src="images/past/landingpage.png" alt="Landing Page"></img>
        </Bg3>

        <Bg2>
          <ContentBodyWrapper>
            <Header>
              Design Challenge 2 :<span> Card Design</span>
            </Header>
            <Body>
              I chose the card design format as a good way to demonstrate the
              production status of shoes, so when shoes were prototyped or
              launched, it displays on the same card. Also, the like button and
              the view number on the card can be shown to give users extra
              information about product
            </Body>
            <img src="images/past/card.png" alt="card"></img>

            <ContentSectionWrapper>
              <ContentGroupSectionWrapper>
                <BoldBody>Challenge</BoldBody>
                <Body>
                  1.During the production process, as the designer picked the
                  material, the color of the shoe was changed or added from the
                  original prototype.
                </Body>
                <Body>
                  2.Because design cards are made in one, having a separated
                  cards by color and style require extra cost in development.
                </Body>
              </ContentGroupSectionWrapper>
              <ContentGroupSectionWrapper>
                <Body>
                  <BoldBody>Solution</BoldBody>
                </Body>
                <Body>
                  Both color and style options are displayed on hover state. On
                  mobile, color and style options are showing without hover (see
                  image below).
                </Body>
              </ContentGroupSectionWrapper>
            </ContentSectionWrapper>
          </ContentBodyWrapper>
        </Bg2>

        <Bg1>
          <ContentBodyWrapper>
            <Header>Result / Outcome (a month after redesign website…)</Header>
            <Body>
              A month after the website is launched, the overall retention rate
              had risen according to the metric service, Mixpanel. - The number
              of click to brand page (per user) increased from 36% to 56% - An
              average number of the product page that users visit from 8.7% to
              33.4% - An average number of the brand page that users visit from
              1.21 pages to 2.25 pages - The number of likes increase after
              redesign website : +220 - An average number of click per user
              increased from 4% to 9%
            </Body>
          </ContentBodyWrapper>
        </Bg1>

        <Bg4>
          <span>
            <img src="images/past/checkout.png" alt="Checkout"></img>
          </span>
        </Bg4>

        <Bg2>
          <ContentMenuWrapper>
            <Link to="/changeplan">
              <Menu>
                <span role="img" aria-label="next">
                  ⬅
                </span>
                <span> Previous project</span>
              </Menu>
            </Link>
            <Link to="/nordstrom">
              <Menu>
                Next project{" "}
                <span role="img" aria-label="next">
                  ⮕
                </span>
              </Menu>
            </Link>
          </ContentMenuWrapper>
        </Bg2>
      </Wrapper>
    </Layout>
  )
}

export default Rooy

const animation = keyframes`
from {opacity: 0; transform: translateY(10px);};
to {opacity: 1; transform: translateY(0px);};
`

const Wrapper = styled.div`
  width: 100%;
`

const ContentWrapper = styled.div`
  background: url("/images/past/rooy.png");
  height: 600px;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: end;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 944px) {
    height: 400px;
  }
`
const ContentHeaderWrapper = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 72px;
  line-height: 100%;
  color: #fff;
  padding-bottom: 86px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  animation: ${animation} 1s 0.2s forwards;
  opacity: 0;
`

const ContentBodyWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 24px;
  }
`
const ContentSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
  padding-top: 24px;

  @media (max-width: 944px) {
    grid-template-columns: 1fr;
  }
`

const ContentGroupSectionWrapper = styled.div``

const Bg1 = styled.div`
  width: 100%;
  padding: 86px 200px;

  @media (max-width: 944px) {
    padding: 24px;
  }
`

const Bg2 = styled.div`
  width: 100%;
  padding: 86px 200px;
  background-color: #fff;

  @media (max-width: 944px) {
    padding: 24px;
  }
`

const Bg3 = styled.div`
  width: 100%;
  background-color: #242424;

  img {
    display: block;
    max-width: 1600px;
    margin: 0 auto;

    @media (max-width: 1600px) {
      width: 100%;
    }
  }
`

const Bg4 = styled.div`
  width: 100%;
  background-color: white;

  img {
    display: block;
    max-width: 1600px;
    margin: 0 auto;

    @media (max-width: 1600px) {
      width: 100%;
    }
  }
`

const Header = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 130%;
  /* or 31px */

  color: #6070a0;
  margin-bottom: 24px;

  span {
    color: #000;
  }
`

const Body = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;
  /* or 23px */

  /* Text */

  color: #000000;
  margin-bottom: 24px;
`

const BoldBody = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  /* or 23px */

  /* Text */

  color: #000000;
  margin-bottom: 24px;
  text-align: center;

  @media (max-width: 944px) {
    text-align: start;
  }
`
const ContentMenuWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
`
const Menu = styled.div`
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */

  color: #2d577d;

  :hover {
    opacity: 0.8;
  }
`
const CloseBtn = styled.div`
  display: block;
  position: fixed;
  margin: 35px 30px;

  :hover {
    opacity: 0.4;
  }

  @media (max-width: 944px) {
    display: none;
  }

  img {
    width: 50px;
    height: 50px;
    opacity: 0.7;
  }
`
